
import React, { useRef, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import CookieNotice from './CookieNotice';
import { Link } from 'react-router-dom';
import ViewDatenschutz from './ViewDatenschutz';
import ViewStart from './ViewStart';
import ViewInfo from './ViewInfo';
import ViewExamples from './ViewExamples';
import ViewNotFound from './ViewNotFound';

import './App.css';
import ViewGenerator from './ViewGenerator';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const refForm = useRef(null)
  const scrollToForm = () => scrollToRef(refForm)

  return (
    <Router>
      <div className="App">

        <header className="App-header">
          <Link to="/"><h1><img src="/layout-images/page-logo-small.png" alt="Logo small Ausweisgenerator.de" />Ausweisgenerator</h1></Link>
          <Menu
            right
          >
            <div>
              <Link to="/">Start</Link>
            </div>
            <div>
              <Link to="/kinderausweise">Beispiele für Kinderausweise</Link>
            </div>
            <div>
              <Link to="/generator"><strong>Generator</strong></Link>
            </div>
            <div>
              <Link to="/datenschutz">Datenschutz</Link>
            </div>
            <div>
              <Link to="/info">Impressum</Link>
            </div>
          </Menu>
        </header>
        <div className="page-wrapper" ref={refForm}>
          <Switch>
            <Route path={'/info'} component={ViewInfo} />
            <Route path={'/kinderausweise'} component={ViewExamples} />
            <Route path={'/generator'} component={ViewGenerator} />
            <Route path={'/datenschutz'} component={ViewDatenschutz} />
            <Route path={'/'} component={ViewStart} />
            <Route path="*" component={ViewNotFound} />
          </Switch>
        </div>

        <footer className="footer">
          <div className="left"> </div>
          <div className="center"> </div>
          <div className="right"> </div>
        </footer>

        <CookieNotice />
      </div>
    </Router>
  );
}

export default App;
