import React from 'react';

function AdBlock(props) {

    return (
        <div className="full-block">
            {props.format === 'horizontal' &&
                <ins
                    className="adsbygoogle"
                    style={{ 'display': 'inline-block', 'width': '320px', 'height': '100px' }}
                    data-ad-client="ca-pub-7604412297405337"
                    data-ad-slot="4988834853"
                />}
            {props.format === 'auto' &&
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-7604412297405337"
                    data-ad-slot="5741745024"
                    data-ad-format="if mobile, show rectangle, else show auto"
                    data-full-width-responsive="true"
                />}
        </div>
    );
}

export default AdBlock;
