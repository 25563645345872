import React from 'react';
import Helmet from 'helmet';
import TrackingPageView from './TrackingPageView';
import AdBlock from './AdBlock';

import { Link } from 'react-router-dom';

function ViewExamples(props) {

    return (
        <div className="content-page">
            <Helmet>
                <title>Kinderausweise - Ausweisgenerator</title>
                <meta property="og:title" content="Ausweisgenerator.de - Kinder- und Spiel-Ausweise vieler Art"/>
                <meta property="og:description" content="Erstelle noch heute für dich oder deine Kinder Spiel-Ausweise. Polizei, Feuerwehr, Tierarzt, probiere die vielen verschiedenen Sets aus."></meta>
                <meta property="og:url" content="https://www.ausweisgenerator.de/kinderausweise"></meta>
                <meta property="og:image" content="https://www.ausweisgenerator.de/examples/polizei.png" />
                <meta property="og:type" content="article"></meta>
            </Helmet>
            <h1>Kinderausweise</h1>
            <h2>Lustige Ausweise zum Spielen</h2>
            <AdBlock format="auto" />
            <div className="content-wrapper">

                <p>
                    Zu den drei Tierausweisen haben wir zahlreiche Themen zur Auswahl.
                    </p>

                <div className="content-sub">
                    <img src="/examples/hund.png" alt="Beispiel Hunde Kinderausweis" />
                    <span>So sieht der Hunde-Ausweis aus</span>
                </div>
                <div className="content-sub">
                    <img src="/examples/katze.png" alt="Beispiel Katzen Kinderausweis" />
                    <span>Unser Katzen-Ausweis</span>
                </div>
                <div className="content-sub">
                    <img src="/examples/hund.png" alt="Beispiel Pferde Kinderausweis" />
                    <span>So kann der Pferde-Ausweis aussehen</span>
                </div>


                <div className="content-sub">
                    <h3>Rennfahrer Kinderausweis</h3>
                    <img src="/examples/rennfahrer-junge.png" alt="Beispiel Rennfahrer Kinderausweis" />
                    <p>
                        Der Rennfahrer-Ausweis passt natürlich Super, wenn man mit Ferngesteuerten Autos, Dronen oder auf der Carrera-Bahn unterwegs ist.
                    </p>
                </div>

                <div className="content-sub">
                    <h3>Detektiv Kinderausweis</h3>
                    <img src="/examples/detektiv-maedchen.png" alt="Beispiel Detektivin Kinderausweis" />
                    <p>
                        Ideal für alle Spürnasen. Alternativ haben wir auch noch ein Thema "Spezial-Agent" im Generator.
                    </p>
                </div>
                <div className="content-sub">
                    <img src="/examples/detektiv-maedchen-voll.png" alt="Beispiel Detektivin Kinderausweis mit Rückseite" />
                    <span>Und so sieht ein Ausweis samt Rückseite aus</span>
                </div>

                <div className="content-sub">
                    <h3>Polizei Kinderausweis</h3>
                    <img src="/examples/polizei-junge.png" alt="Beispiel Polizei Kinderausweis" />
                    <p>
                        Wer spielt nicht gern Polizei? Ein Themen-Geburtstag lässt sich so noch etwas mehr tiefe und Spaß geben.
                    </p>
                </div>

                <div className="content-sub">
                    <h3>Doktor, Arzt oder Tieararzt Kinderausweis</h3>
                    <img src="/examples/doktor-maedchen.png" alt="Beispiel Doktor oder Tierarzt Kinderausweis" />
                    <p>
                        Dieser Ausweis ist für alle, die gern Doktor spielen (zum Arztkoffer ggf. direkt dazu drucken) oder die mal Tierarzt sein wollen - vielleicht für die eigenen Plüschtiere.
                    </p>
                </div>



                <p>
                    <Link to="/"><button>Zurück zur Startseite</button></Link>
                    <Link to="/generator"><button>Zum Generator</button></Link>
                </p>
            </div>
            <TrackingPageView />
        </div>
    );
}

export default ViewExamples;
