import htmlToImage from 'html-to-image';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'helmet';
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
const download = require("downloadjs");
const predefImages = [
    'rakete.png', 'agent-2.png', 'alien-2.png', 'alien.png', 'astronaut-2.png', 'astronaut.png', 'ballerina.png', 'cat.jpg', 'chihuahua.jpg', 'detektiv-2.png', 'detektiv.png', 'dino.png', 'drache.png', 'feuerwehr.png', 'frosch.png', 'held-2.jpg', 'held.png', 'junge-2.png', 'junge.png', 'katze-2.jpg', 'katze.png', 'maedchen.png', 'monster-2.jpg', 'monster-3.png', 'monster.png', 'papagei.jpg', 'pferd-2.jpg', 'pferd.jpg', 'polizei-3.png', 'polizei.png', 'prinzessin.png', 'schaf.jpg', 'sheriff-2.png', 'sheriff.png'
];

const colors = [
    { label: 'Keiner', color: 'transparent' },
    { label: 'Schwarz', color: 'black' },
    { label: 'Orange', color: 'orange' },
    { label: 'Rot', color: 'red' },
    { label: 'Blau', color: 'blue' },
    { label: 'Grün', color: 'green' },
    { label: 'Dunkel-Rot', color: 'darkred' },
    { label: 'Dunkel-Blau', color: 'darkblue' },
    { label: 'Dunkel-Grün', color: 'darkgreen' },
    { label: 'Pink', color: 'pink' },
    { label: 'Braun', color: 'brown' },
    { label: 'Gelb', color: 'yellow' }
];


function ViewStart(props) {
    const refPassport = useRef(null)
    const refForm = useRef(null)
    const scrollToPassport = () => scrollToRef(refPassport)
    const scrollToForm = () => scrollToRef(refForm)
    const [uploadImage, setUploadImage] = useState(null);
    const [uploadBackgroundImage, seUploadBackgroundImage] = useState(null);
    const [selectImage, setSelectImage] = useState(predefImages[Math.floor(Math.random() * (predefImages.length - 1))]);
    const [codename, setCodename] = useState('Dein Spitzname');
    const [realname, setRealname] = useState('Dein Name');
    const [organization, setOrganization] = useState('Dein Club');
    const [layout, setLayout] = useState(Math.floor(Math.random() * 6) + 1);
    const [color1, setColor1] = useState(colors[Math.floor(Math.random() * (colors.length - 1))].color);
    const [color2, setColor2] = useState("black");
    const [color3, setColor3] = useState(colors[Math.floor(Math.random() * (colors.length - 1))].color);
    const [colorBackground, setColorBackground] = useState("white");
    const [specials, setSpecials] = useState("Was kannst du besonders gut?");


    let onChangeItem = (e) => {

        if (e.target.name === 'codename') setCodename(e.target.value);
        else if (e.target.name === 'realname') setRealname(e.target.value);
        else if (e.target.name === 'organization') setOrganization(e.target.value);
        else if (e.target.name === 'layout') setLayout(e.target.value);
        else if (e.target.name === 'color1') setColor1(e.target.value);
        else if (e.target.name === 'color2') setColor2(e.target.value);
        else if (e.target.name === 'color3') setColor3(e.target.value);
        else if (e.target.name === 'colorBackground') setColorBackground(e.target.value);
        else if (e.target.name === 'specials') setSpecials(e.target.value);
    }

    let setImage = (type, picture) => {

        if (type === 'upload') {
            setUploadImage(picture);
        } else if (type === 'uploadBackground') {
            seUploadBackgroundImage(picture);
        } else {
            setSelectImage(picture);
        }
    }

    let clickPrint = () => {
        var node = document.getElementById('passport-wrapper');
        console.log('node', node);
        htmlToImage.toPng(node, { quality: 0.95, width: 1200, height: 1000 })
            .then(function (dataUrl) {
                // var img = new Image();
                // img.src = dataUrl;
                // document.body.appendChild(img);
                download(dataUrl, `${codename}${organization}.png`);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    return (
        <div className="start">
            <Helmet>
                <title>Ausweisgenerator - Generator</title>
                <meta property="og:title" content="Ausweisgenerator.de - Kinder- und Spiel-Ausweise vieler Art"/>
                <meta property="og:description" content="Erstelle noch heute für dich oder deine Kinder Spiel-Ausweise. Polizei, Feuerwehr, Tierarzt, probiere die vielen verschiedenen Sets aus."></meta>
                <meta property="og:url" content="https://www.ausweisgenerator.de/"></meta>
                <meta property="og:image" content="https://www.ausweisgenerator.de/examples/hund.png" />
                <meta property="og:type" content="article"></meta>
            </Helmet>
            <div className="App-entry">

                <p>Hallo, hier kannst du dir ganz einfach einen Ausweis erstellen! Ob für Kindergeburtstage, Spielkreise oder Kindergärten.</p>
                <p>Von Polizei über Feuerwehr, Superheld bis Detekiv, hier kannst du aus vielen vorgefertigten Sets auswählen.</p>
                <p>Sollte nicht das passende dabei sein, kannst du über die erweiterten Funktionen deinen ganz eigenen Ausweis zusammenstellen.</p>
                <p>Viel Spaß!</p>
            </div>

            <div className="element printer">
                <Link to="/generator"><button className="home">Zum Generator</button></Link>
            </div>

            <div className="App-more" itemscope itemtype="https://schema.org/FAQPage">

                <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 itemprop="name">Wofür kann ich den Ausweisgenerator nutzen?</h2>
                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <p itemprop="text">Der Ausweisgenerator erstellt Spiel-Ausweise. Diese kann man für Kindergeburtstage oder einfach zum Spielen zuhause erstellen. Auch als Accessoir zu einer Faschings-, Karnevals- oder Halloween-Verkleidung kann ein lustiger Ausweis passen.</p>
                    </div>
                </div>

                <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 itemprop="name">Welche Arten von Ausweisen kann ich mit dem Ausweisgenerator erstellen?</h2>
                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <p itemprop="text">Im Ausweisgenerator gibt es viele vorgefertigte Sets, wie Polizist, Detektiv, Tierarzt oder Geheimagent.</p>
                    </div>
                </div>
                <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 itemprop="name">Was kann ich mit den erweiterten Einstellungen im Ausweisgenerator machen?</h2>
                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <p itemprop="text">
                            Unter den erweiterten Einstellungen kannst du dir fast vollständig individuelle Ausweise generieren.
                            <br />
                            Du kannst dir aus den vorgegebenen Bildern lustige Passfotos auswählen oder dir auch einen eignen Hintergrund hochladen.
                        </p>
                    </div>
                </div>

                <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 itemprop="name">Kann ich auch für mein Haustier einen Ausweis erstellen?</h2>
                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <p itemprop="text">
                            Klar kannst du mit dem Ausweisgenerator auch für dein Haustier einen Ausweis erstellen! 
                            <br/>
                            Wir haben für Hund, Katze und Pferd sogar vordefinierte Sets. Probiere es gleich aus!
                        </p>
                    </div>
                </div>
            </div>

            {/* <JSONLD>
                <Product name="Product Name">
                    <AggregateRating ratingValue={4.3} reviewCount={197} />
                    <GenericCollection type="review">
                        <Review name="It's awesome" reviewBody="This is Great! My family loves it" datePublished="11/22/1963">
                            <Author name="Jerry" />
                            <Location name="Chicago, IL" />
                            <Rating ratingValue={5} />
                        </Review>
                        <Review name="Very cool" reviewBody="I like this a lot. Very cool product" datePublished="11/22/1963">
                            <Author name="Cool Carl" />
                            <Location name="Chicago, IL" />
                            <Rating ratingValue={4} />
                        </Review>
                    </GenericCollection>
                </Product>
            </JSONLD> */}

        </div>
    );
}

export default ViewStart;
