import React from 'react';
import Helmet from 'helmet';
import TrackingPageView from './TrackingPageView';
import AdBlock from './AdBlock';

import { Link } from 'react-router-dom';

function ViewInfo(props) {

    return (
        <div className="content-page">
                <Helmet>
                    <title>Info - Ausweisgenerator</title>
                    <meta name="ROBOTS" content="NOINDEX,FOLLOW" />
                </Helmet>
                <h1>Info Seite</h1>
                <AdBlock format="auto" />
                <div className="content-wrapper">
                    <p>
                        Viebrock Software<br />
                        Im Winkel 6a<br />
                        21244 Buchholz<br />
                        Niedersachsen<br />
                        Deutschland<br />
                        E-Mail: office@viebrock-software.de<br />
                        <br /><br />
                        Steuernummer: 15/145/15455
                    </p>

                    <p>
                        <Link to="/">
                            <button>Zurück zur Startseite</button>
                        </Link>
                    </p>
                </div>
                <TrackingPageView />
            </div>
    );
}

export default ViewInfo;
