import React, { useState } from 'react';
import ImageUploader from 'react-images-upload';

function Form(props) {

    const [showExtra, setShowExtra] = useState(false);

    const layouts = [
        { val: 9, label: 'Tierarzt' },
        { val: 10, label: 'Spezial Agent' },
        { val: 11, label: 'Rennfahrer' },
        { val: 12, label: 'Polizei' },
        { val: 13, label: 'Superheld' },
        { val: 14, label: 'Feuerwehr' },
        { val: 15, label: 'Detektiv' },
        { val: 16, label: 'Western' },
        { val: 17, label: 'Katze' },
        { val: 18, label: 'Hund' },
        { val: 19, label: 'Pferd' },
        { val: 20, label: 'Koch' },
        { val: 21, label: 'Pirat' },
        { val: 22, label: 'Dino T-Rex' },
        { val: 23, label: 'Dino Triceratops' },
    ];
    const predefImages = [
        'CAT.png', 'DOG.png', 'HORSE.png', 'FIREMAN.png', 'FIREMAN_2.png', 'SPECIAL_AGENT.png', 'SPECIAL_AGENT_2.png', 'HERO.png', 'HERO_2.png', 'COWBOY.png', 'COWBOY_2.png', 'POLICE.png', 'POLICE_2.png', 'VETERINARIAN.png', 'VETERINARIAN_2.png', 'DETECTIVE.png', 'DETECTIVE_2.png', 'CHEF.png', 'CHEF_2.png',
        'DINOSAUR_TRICERATOPS.png', 'DINOSAUR_TREX.png', 'PIRATE.png', 'PIRATE_2.png',
        'rakete.png', 'agent-2.png', 'alien-2.png', 'alien.png', 'astronaut-2.png', 'astronaut.png', 'ballerina.png', 'cat.jpg', 'chihuahua.jpg', 'detektiv-2.png', 'detektiv.png', 'dino.png', 'drache.png', 'feuerwehr.png', 'frosch.png', 'held-2.jpg', 'held.png', 'junge-2.png', 'junge.png', 'katze-2.jpg', 'katze.png', 'maedchen.png', 'monster-2.jpg', 'monster-3.png', 'monster.png', 'papagei.jpg', 'pferd-2.jpg', 'pferd.jpg', 'polizei-3.png', 'polizei.png', 'prinzessin.png', 'schaf.jpg', 'sheriff-2.png', 'sheriff.png'
    ];
    const colors = [
        { label: 'Keiner', color: '255,255,255' },
        { label: 'Schwarz', color: '0,0,0' },
        { label: 'Weiß', color: '255,255,255' },
        { label: 'Orange', color: '255,165,0' },
        { label: 'Rot', color: '255,0,0' },
        { label: 'Blau', color: '0,0,255' },
        { label: 'Grün', color: '0,255,0' },
        { label: 'Dunkel-Rot', color: '204,0,0' },
        { label: 'Dunkel-Blau', color: '0,0,153' },
        { label: 'Dunkel-Grün', color: '0,0,102' },
        { label: 'Pink', color: '255,0,255' },
        { label: 'Braun', color: '153,76,0' },
        { label: 'Gelb', color: '255,255,0' }
    ];

    let loadPreset = (setName) => {

        if (setName === 'agent' || setName === 'agent2') {

            props.changeItem({ target: { value: '0,0,255', name: 'color1' } });
            props.changeItem({ target: { value: '0,0,255', name: 'color2' } });
            props.changeItem({ target: { value: '0,0,255', name: 'color3' } });
            props.changeItem({ target: { value: '10', name: 'layout' } });
            props.setImage('select', setName === 'agent' ? 'SPECIAL_AGENT.png' : 'SPECIAL_AGENT_2.png');
            props.changeItem({ target: { value: 'MI-6', name: 'organization' } });
            props.changeItem({ target: { value: 'Null Null Sieben', name: 'codename' } });
            props.changeItem({ target: { value: 'James Bond', name: 'realname' } });
            props.changeItem({ target: { value: 'Bösewichte fangen, die Welt retten', name: 'specials' } });
        }

        else if (setName === 'detective' || setName === 'detective2') {

            props.changeItem({ target: { value: '0,0,102', name: 'color1' } });
            props.changeItem({ target: { value: '0,255,0', name: 'color2' } });
            props.changeItem({ target: { value: '0,0,102', name: 'color3' } });
            props.changeItem({ target: { value: '15', name: 'layout' } });
            props.setImage('select', setName === 'detective' ? 'DETECTIVE.png' : 'DETECTIVE_2.png');
            props.changeItem({ target: { value: 'Detektei Spürnasen', name: 'organization' } });
            props.changeItem({ target: { value: 'Haupt Inspector', name: 'codename' } });
            props.changeItem({ target: { value: 'Jupiter', name: 'realname' } });
            props.changeItem({ target: { value: 'Rätseln, Kombinieren, Puzzlen', name: 'specials' } });
        }
        else if (setName === 'cowboy' || setName === 'cowboy2') {

            props.changeItem({ target: { value: '153,76,0', name: 'color1' } });
            props.changeItem({ target: { value: '153,76,0', name: 'color2' } });
            props.changeItem({ target: { value: '153,76,0', name: 'color3' } });
            props.changeItem({ target: { value: '16', name: 'layout' } });
            props.setImage('select', setName === 'cowboy' ? 'COWBOY.png' : 'COWBOY_2.png');
            props.changeItem({ target: { value: 'Recht & Ordnung', name: 'organization' } });
            props.changeItem({ target: { value: 'Buffalo Bill', name: 'codename' } });
            props.changeItem({ target: { value: 'Bill Turner', name: 'realname' } });
            props.changeItem({ target: { value: 'Reiten, Ganoven schnappen', name: 'specials' } });
        }
        else if (setName === 'hero' || setName === 'hero2') {

            props.changeItem({ target: { value: '0,0,255', name: 'color1' } });
            props.changeItem({ target: { value: '0,0,0', name: 'color2' } });
            props.changeItem({ target: { value: '255,0,0', name: 'color3' } });
            props.changeItem({ target: { value: '13', name: 'layout' } });
            props.setImage('select', setName === 'hero' ? 'HERO.png' : 'HERO_2.png');
            props.changeItem({ target: { value: 'Superhelden Utd', name: 'organization' } });
            props.changeItem({ target: { value: 'Red Cape Man', name: 'codename' } });
            props.changeItem({ target: { value: 'Peter Parker', name: 'realname' } });
            props.changeItem({ target: { value: 'Die Menschheit retten', name: 'specials' } });
        }
        else if (setName === 'police' || setName === 'police2') {

            props.changeItem({ target: { value: '0,0,153', name: 'color1' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color2' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color3' } });
            props.changeItem({ target: { value: '12', name: 'layout' } });
            props.setImage('select', setName === 'police' ? 'POLICE.png' : 'POLICE_2.png');
            props.changeItem({ target: { value: 'Polizei Neustadt', name: 'organization' } });
            props.changeItem({ target: { value: '1. Polizist', name: 'codename' } });
            props.changeItem({ target: { value: 'Kommissar Glockner', name: 'realname' } });
            props.changeItem({ target: { value: 'Verbrechen aufklären, Ganoven schnappen', name: 'specials' } });
        }

        else if (setName === 'chef' || setName === 'chef2') {

            props.changeItem({ target: { value: '0,0,153', name: 'color1' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color2' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color3' } });
            props.changeItem({ target: { value: '20', name: 'layout' } });
            props.setImage('select', setName === 'chef' ? 'CHEF.png' : 'CHEF_2.png');
            props.changeItem({ target: { value: 'Lieblingsrestaurant', name: 'organization' } });
            props.changeItem({ target: { value: '5 Sterne Koch', name: 'codename' } });
            props.changeItem({ target: { value: 'Super Koch', name: 'realname' } });
            props.changeItem({ target: { value: 'Frisches Gemüse, Leckere Nudeln', name: 'specials' } });
        }

        else if (setName === 'pirate' || setName === 'pirate2') {

            props.changeItem({ target: { value: '0,0,153', name: 'color1' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color2' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color3' } });
            props.changeItem({ target: { value: '21', name: 'layout' } });
            props.setImage('select', setName === 'pirate' ? 'PIRATE.png' : 'PIRATE_2.png');
            props.changeItem({ target: { value: 'Schiff: Black Perl', name: 'organization' } });
            props.changeItem({ target: { value: '1. Steuermann', name: 'codename' } });
            props.changeItem({ target: { value: 'Captain Hook', name: 'realname' } });
            props.changeItem({ target: { value: 'Schätze verstecken', name: 'specials' } });
        }

        else if (setName === 'dinosaur') {

            props.changeItem({ target: { value: '0,0,153', name: 'color1' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color2' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color3' } });
            props.changeItem({ target: { value: '22', name: 'layout' } });
            props.setImage('select', setName === 'dinosaur' ? 'DINOSAUR_TRICERATOPS.png' : 'DINOSAUR_TREX.png');
            props.changeItem({ target: { value: 'Urzeit', name: 'organization' } });
            props.changeItem({ target: { value: 'Bester Dino', name: 'codename' } });
            props.changeItem({ target: { value: 'Dino', name: 'realname' } });
            props.changeItem({ target: { value: 'Was Dinos halt so mögen', name: 'specials' } });
        }
        else if (setName === 'dinosaur2') {

            props.changeItem({ target: { value: '0,0,153', name: 'color1' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color2' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color3' } });
            props.changeItem({ target: { value: '23', name: 'layout' } });
            props.setImage('select','DINOSAUR_TREX.png');
            props.changeItem({ target: { value: 'Urzeit', name: 'organization' } });
            props.changeItem({ target: { value: 'Bester Dino', name: 'codename' } });
            props.changeItem({ target: { value: 'Dino', name: 'realname' } });
            props.changeItem({ target: { value: 'Alle anderen Dinos auffressen', name: 'specials' } });
        }

        else if (setName === 'race' || setName === 'race2') {

            props.changeItem({ target: { value: '0,0,153', name: 'color1' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color2' } });
            props.changeItem({ target: { value: '0,0,153', name: 'color3' } });
            props.changeItem({ target: { value: '11', name: 'layout' } });
            props.setImage('select', setName === 'race' ? 'RACING_DRIVER.png' : 'RACING_DRIVER_2.png');
            props.changeItem({ target: { value: 'Formel 100', name: 'organization' } });
            props.changeItem({ target: { value: 'Fahrer Nr 1', name: 'codename' } });
            props.changeItem({ target: { value: 'Peter Power', name: 'realname' } });
            props.changeItem({ target: { value: 'Super-Überholer', name: 'specials' } });
        }
        else if (setName === 'fire' || setName === 'fire2') {

            props.changeItem({ target: { value: '204,0,0', name: 'color1' } });
            props.changeItem({ target: { value: '255,0,0', name: 'color2' } });
            props.changeItem({ target: { value: '255,0,0', name: 'color3' } });
            props.changeItem({ target: { value: '14', name: 'layout' } });
            props.setImage('select', setName === 'fire' ? 'FIREMAN.png' : 'FIREMAN_2.png');
            props.changeItem({ target: { value: 'Freiwillige Feuerwehr', name: 'organization' } });
            props.changeItem({ target: { value: 'Haupt Feuerwehrmann', name: 'codename' } });
            props.changeItem({ target: { value: 'Sam', name: 'realname' } });
            props.changeItem({ target: { value: 'Löschen, Bergen, Retten', name: 'specials' } });
        } else if (setName === 'vet' || setName === 'vet2') {

            props.changeItem({ target: { value: '204,0,0', name: 'color1' } });
            props.changeItem({ target: { value: '255,0,0', name: 'color2' } });
            props.changeItem({ target: { value: '255,0,0', name: 'color3' } });
            props.changeItem({ target: { value: '9', name: 'layout' } });
            props.setImage('select', setName === 'vet' ? 'VETERINARIAN.png' : 'VETERINARIAN_2.png');
            props.changeItem({ target: { value: 'Tierarzt', name: 'organization' } });
            props.changeItem({ target: { value: 'Doktor Bieber', name: 'codename' } });
            props.changeItem({ target: { value: 'Betrtam', name: 'realname' } });
            props.changeItem({ target: { value: 'Leben retten und Tiere pflegen', name: 'specials' } });
        } else if (setName === 'dog') {

            props.changeItem({ target: { value: '204,0,0', name: 'color1' } });
            props.changeItem({ target: { value: '255,0,0', name: 'color2' } });
            props.changeItem({ target: { value: '255,0,0', name: 'color3' } });
            props.changeItem({ target: { value: '18', name: 'layout' } });
            props.setImage('select', 'DOG.png');
            props.changeItem({ target: { value: 'Hund', name: 'organization' } });
            props.changeItem({ target: { value: 'Wuffi der Hund', name: 'codename' } });
            props.changeItem({ target: { value: 'Wuffi ', name: 'realname' } });
            props.changeItem({ target: { value: 'Bellen, toben, spielen', name: 'specials' } });
        } else if (setName === 'cat') {

            props.changeItem({ target: { value: '204,0,0', name: 'color1' } });
            props.changeItem({ target: { value: '255,0,0', name: 'color2' } });
            props.changeItem({ target: { value: '255,0,0', name: 'color3' } });
            props.changeItem({ target: { value: '17', name: 'layout' } });
            props.setImage('select', 'CAT.png');
            props.changeItem({ target: { value: 'Katze', name: 'organization' } });
            props.changeItem({ target: { value: 'Maunzi die Katze', name: 'codename' } });
            props.changeItem({ target: { value: 'Maunzi', name: 'realname' } });
            props.changeItem({ target: { value: 'Schmusen, klettern und schlafen', name: 'specials' } });
        } else if (setName === 'horse') {

            props.changeItem({ target: { value: '255,165,0', name: 'color1' } });
            props.changeItem({ target: { value: '255,165,0', name: 'color2' } });
            props.changeItem({ target: { value: '255,165,0', name: 'color3' } });
            props.changeItem({ target: { value: '19', name: 'layout' } });
            props.setImage('select', 'HORSE.png');
            props.changeItem({ target: { value: 'Pferd', name: 'organization' } });
            props.changeItem({ target: { value: 'Springpferd Nr 1', name: 'codename' } });
            props.changeItem({ target: { value: 'Bronco', name: 'realname' } });
            props.changeItem({ target: { value: 'Gallopieren und über Hindernisse springen', name: 'specials' } });
        }

        props.loadPreview();
        props.scrollToPassport();

    }

    let onDrop = (picture) => {

        props.setImage('upload', picture[0]);
    };
    let onDrop2 = (picture) => {

        console.log(picture);
        props.setImage('uploadBackground', picture[0]);
    };

    return (
        <div className="Form">


            <div className="formatting">

                <div className="element-sets container">
                    <label>Vordefinierte Sets</label>
                    {/* <input type="checkbox" class="select-checkbox" id="c1" />
                    <label for="c1" class="select-label">Sets anzeigen</label> */}
                    <div class="select-wrap">
                        <ul class="select">
                            <li><button onClick={() => loadPreset('detective')}>Detektiv Mädchen</button></li>
                            <li><button onClick={() => loadPreset('detective2')}>Detektiv Junge</button></li>
                            <li><button onClick={() => loadPreset('agent')}>Agent Mädchen</button></li>
                            <li><button onClick={() => loadPreset('agent2')}>Agent Junge</button></li>
                            <li><button onClick={() => loadPreset('cowboy')}>Sheriff Mädchen</button></li>
                            <li><button onClick={() => loadPreset('cowboy2')}>Sheriff Junge</button></li>
                            <li><button onClick={() => loadPreset('hero')}>Held Mädchen</button></li>
                            <li><button onClick={() => loadPreset('hero2')}>Held Junge</button></li>
                            <li><button onClick={() => loadPreset('police')}>Polizei Mädchen</button></li>
                            <li><button onClick={() => loadPreset('police2')}>Polizei Junge</button></li>
                            <li><button onClick={() => loadPreset('fire')}>Feuerwehr Mädchen</button></li>
                            <li><button onClick={() => loadPreset('fire2')}>Feuerwehr Junge</button></li>
                            <li><button onClick={() => loadPreset('race')}>Rennfahrer Mädchen</button></li>
                            <li><button onClick={() => loadPreset('race2')}>Rennfahrer Junge</button></li>
                            <li><button onClick={() => loadPreset('vet')}>Tierarzt Mädchen</button></li>
                            <li><button onClick={() => loadPreset('vet2')}>Tierarzt Junge</button></li>
                            <li><button onClick={() => loadPreset('chef')}>Koch Mädchen</button></li>
                            <li><button onClick={() => loadPreset('chef2')}>Koch Junge</button></li>
                            <li><button onClick={() => loadPreset('pirate')}>Pirat Mädchen</button></li>
                            <li><button onClick={() => loadPreset('pirate2')}>Pirat Junge</button></li>
                            <li><button onClick={() => loadPreset('dinosaur')}>Triceratops</button></li>
                            <li><button onClick={() => loadPreset('dinosaur2')}>T-Rex</button></li>
                            <li><button onClick={() => loadPreset('cat')}>KATZE</button></li>
                            <li><button onClick={() => loadPreset('dog')}>HUND</button></li>
                            <li><button onClick={() => loadPreset('horse')}>PFERD</button></li>
                        </ul>
                    </div>

                </div>
            </div>

            <div className="formatting">
                <div className="element">
                    <label>Codename</label>
                    <input type="text" name="codename" value={props.codename} placeholder="Codename" onChange={props.changeItem} />
                </div>

                <div className="element">
                    <label>Name</label>
                    <input type="text" name="realname" value={props.realname} placeholder="Dein Name" onChange={props.changeItem} />
                </div>

                <div className="element">
                    <label>Name Organisation</label>
                    <input type="text" name="organization" value={props.organization} placeholder="Deine Firma" onChange={props.changeItem} />
                </div>

                <div className="element">
                    <label>Spezialfähigkeiten</label>
                    <input type="text" name="specials" value={props.specials} placeholder="Spezielle Kenntnisse" onChange={props.changeItem} />
                </div>
                <div className="clearer"></div>
            </div>



            <div className="formatting">
                <div className="element2">
                    <button className={`btn-extra-options ${showExtra ? 'opened' : 'closed'}`} onClick={() => setShowExtra(!showExtra)}>Erweiterte Einstellungen</button>
                </div>
            </div>

            {showExtra && <div className="extras">


                <div className="formatting">

                    <div className="element">
                        <label>Eigenen Hintergrund auswählen</label>
                        <ImageUploader
                            singleImage={true}
                            withIcon={false}
                            buttonText='Eigener Hintergrund'
                            onChange={onDrop2}
                            imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                            maxFileSize={5242880}
                        />

                        {props.uploadBackgroundImage != null && <div>
                            {props.uploadBackgroundImage.name}<button onClick={() => props.setImage('uploadBackground', null)}>[x]</button>
                        </div>}
                    </div>

                    <div className="element">
                        <label>Persönliches Passbild auswählen</label>
                        <ImageUploader
                            singleImage={true}
                            withIcon={false}
                            buttonText='Eigenes Ausweisbild'
                            onChange={onDrop}
                            imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                            maxFileSize={5242880}
                        />

                        {props.uploadImage != null && <div>
                            {props.uploadImage.name}<button onClick={() => props.setImage('upload', null)}>[x]</button>
                        </div>}
                    </div>


                    <div className="element element-full">
                        <label>Auswahl Passbild auswählen</label>
                        <div className="predef-images">
                            {predefImages.map(image => {
                                return (
                                    <img key={image}
                                        src={`predefined-images/${image}`}
                                        width="50"
                                        height="50"
                                        className={image === props.selectImage ? 'selected' : 'not'}
                                        onClick={() => props.setImage('select', image)}
                                    />
                                )
                            })}
                            <div className="clearer"></div>
                        </div>
                    </div>
                    <div className="clearer"></div>
                </div>

                <div className="formatting">

                    <div className="element">
                        <label>Layout wählen</label>
                        <select name="layout" value={props.layout} onChange={props.changeItem}>
                            {layouts.map(lay => {
                                return (
                                    <option value={lay.val} key={lay.val}>Layout {lay.label}</option>
                                )
                            })}
                        </select>
                    </div>
                    
                    <div className="element">
                        <label>Farbe 2 (Schrift) wählen</label>
                        <select name="color2" value={props.color2} onChange={props.changeItem}>
                            {colors.map(col => {
                                return (
                                    <option value={col.color} key={col.color}>{col.label}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="element">
                        <label>Systemfarbe (Rahmen) wählen</label>
                        <select name="color3" value={props.color3} onChange={props.changeItem}>
                            {colors.map(col => {
                                return (
                                    <option value={col.color} key={col.color}>{col.label}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="clearer"></div>
                </div>

            </div>}

        </div>
    );
}

export default Form;
