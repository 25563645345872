import React from 'react';

function TrackingPageView() {

    if (window.ga) {
            
        var page = document.location.pathname;
        window.ga('set', 'page', page);
        window.ga('send', 'pageview');    
    }

    //gtag
    
    return (
        <span />
    );
}

export default TrackingPageView;
