import htmlToImage from 'html-to-image';
import React, { useRef, useState } from 'react';
import Form from './Form';
import Helmet from 'helmet';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
const download = require("downloadjs");
const predefImages = [
    'CAT.png', 'DOG.png', 'HORSE.png', 'FIREMAN.png', 'FIREMAN_2.png', 'SPECIAL_AGENT.png', 
    'SPECIAL_AGENT_2.png', 'HERO.png', 'HERO_2.png', 'COWBOY.png', 'COWBOY_2.png', 'POLICE.png', 
    'POLICE_2.png', 'VETERINARIAN.png', 'VETERINARIAN_2.png', 'DETECTIVE.png', 'DETECTIVE_2.png',
    'CHEF.png', 'CHEF_2.png', 'PIRATE.png', 'PIRATE_2.png', 'DINOSAUR_TRICERATOPS.png', 'DINOSAUR_TREX.png'
];

const colors = [
    { label: 'Keiner', color: '255,255,255' },
        { label: 'Schwarz', color: '0,0,0' },
        { label: 'Weiß', color: '255,255,255' },
        { label: 'Orange', color: '255,165,0' },
        { label: 'Rot', color: '255,0,0' },
        { label: 'Blau', color: '0,0,255' },
        { label: 'Grün', color: '0,255,0' },
        { label: 'Dunkel-Rot', color: '204,0,0' },
        { label: 'Dunkel-Blau', color: '0,0,153' },
        { label: 'Dunkel-Grün', color: '0,0,102' },
        { label: 'Pink', color: '255,0,255' },
        { label: 'Braun', color: '153,76,0' },
        { label: 'Gelb', color: '255,255,0'}
];


function ViewGenerator(props) {
    const refPassport = useRef(null)
    const refForm = useRef(null)
    const scrollToPassport = () => scrollToRef(refPassport)
    const scrollToForm = () => scrollToRef(refForm)
    const [downloadLink, setDownloadLink] = useState("");
    const [generating, setGenerating] = useState(false);
    const [iframeLink, setIframeLink] = useState("");
    const [uploadImage, setUploadImage] = useState(null);
    const [uploadBackgroundImage, seUploadBackgroundImage] = useState(null);
    const [selectImage, setSelectImage] = useState(predefImages[Math.floor(Math.random() * (predefImages.length - 1))]);
    const [codename, setCodename] = useState('Dein Spitzname');
    const [realname, setRealname] = useState('Dein Name');
    const [organization, setOrganization] = useState('Dein Club');
    const [layout, setLayout] = useState(Math.floor(Math.random() * 9) + 10);
    const [color1, setColor1] = useState(colors[Math.floor(Math.random() * (colors.length - 1))].color);
    const [color2, setColor2] = useState("0,0,0");
    const [color3, setColor3] = useState(colors[Math.floor(Math.random() * (colors.length - 1))].color);
    const [colorBackground, setColorBackground] = useState("255,255,255");
    const [specials, setSpecials] = useState("Was kannst du besonders gut?");




    let onChangeItem = (e) => {

        console.log(e.target.name, e.target.value)
        if (e.target.name === 'codename') setCodename(e.target.value);
        else if (e.target.name === 'realname') setRealname(e.target.value);
        else if (e.target.name === 'organization') setOrganization(e.target.value);
        else if (e.target.name === 'layout') setLayout(e.target.value);
        else if (e.target.name === 'color1') setColor1(e.target.value);
        else if (e.target.name === 'color2') setColor2(e.target.value);
        else if (e.target.name === 'color3') setColor3(e.target.value);
        else if (e.target.name === 'colorBackground') setColorBackground(e.target.value);
        else if (e.target.name === 'specials') setSpecials(e.target.value);
    }

    let setImage = (type, picture) => {

        if (type === 'upload') {
            setUploadImage(picture);
        } else if (type === 'uploadBackground') {
            seUploadBackgroundImage(picture);
        } else {
            setSelectImage(picture);
        }
    }
     
    let clickPrint = async (isPreview) => {

        setDownloadLink("");
        setIframeLink("");
        setGenerating(true);
    
        var data = new FormData()
        data.append('uploadImage', uploadImage)
        data.append('uploadBackgroundImage', uploadBackgroundImage)

        data.append('patternColor', color1)
        data.append('fontColor', color2)
        data.append('borderColor', color3)
        data.append('layout', layout)
        data.append('backgroundColor', colorBackground)
        data.append('codeName', codename)
        data.append('organization', organization)
        data.append('specials', specials)
        data.append('realname', realname)
        data.append('selectImage', selectImage)

        const requestOptions = {
            method: 'POST',
            body: data 
        };
        fetch('https://documents.viebrock-software.de/dlpdf.php', requestOptions)
            .then(response => response.json())
            .then(data => {

                setDownloadLink(data.path);
                setIframeLink(data.path);
                setGenerating(false);
            });

    }


    return (
        <div className="start">
            <Helmet>
                <title>Ausweisgenerator - Generator</title>
                <meta property="og:title" content="Ausweisgenerator.de - Kinder- und Spiel-Ausweise vieler Art"/>
                <meta property="og:description" content="Erstelle noch heute für dich oder deine Kinder Spiel-Ausweise. Polizei, Feuerwehr, Tierarzt, probiere die vielen verschiedenen Sets aus."></meta>
                <meta property="og:url" content="https://www.ausweisgenerator.de/generator"></meta>
                <meta property="og:image" content="https://www.ausweisgenerator.de/examples/held-maedchen.png" />
                <meta property="og:type" content="article"></meta>
            </Helmet>
            <div className="App-content">
                <div ref={refForm}>
                    <Form
                        changeItem={onChangeItem}
                        setImage={setImage}
                        codename={codename}
                        realname={realname}
                        organization={organization}
                        layout={layout}
                        color1={color1}
                        color2={color2}
                        color3={color3}
                        colorBackground={colorBackground}
                        specials={specials}
                        uploadImage={uploadImage}
                        uploadBackgroundImage={uploadBackgroundImage}
                        selectImage={selectImage}
                        scrollToPassport={scrollToPassport}
                        loadPreview={() => clickPrint(true)}
                    /></div>

                
                <div className="element printer">
                <button className="preview" onClick={() => clickPrint(true)}>Vorschau</button>
                    {downloadLink !== '' && <div><a href={downloadLink} target="_blank">Herunterladen</a></div>}
                </div>

                {generating === true && <h3 className="preview">Vorschau wird erstellt</h3>}
                {iframeLink !== '' && <div>
                    <iframe src={iframeLink} width="100%" height="300" /></div>}

                
                    <section ref={refPassport} />
                {iframeLink !== '' &&<div className="element printer">
                    <button onClick={() => clickPrint(true)}>Vorschau</button>
                    {downloadLink !== '' && <div><a href={downloadLink} target="_blank">Herunterladen</a></div>}
                </div>}
            </div>
        </div>
    );
}

export default ViewGenerator;
