import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

function CookieNotice(props) {

    return (
        <CookieConsent
            location="bottom"
            buttonText="Verstanden"
            cookieName="festivalNoticeCookie"
            style={{ background: 'rgb(230, 230, 230)', color: '#2FA4E7' }}
            buttonStyle={{ background: '#2FA4E7', color: '#fff', fontSize: '14px' }}
            expires={150}
        >
            Diese Website benutzt Cookies und blendet Werbung ein. 
            Wenn Sie die Website weiter nutzen, 
            gehen wir von Ihrem Einverständnis der Nutzung aus. 
            Alles weitere unter 
            <Link to="/datenschutz">
                <a 
                    style={{color: 'rgb(24, 109, 146)', paddingLeft: '5px'}} 
                    target="_blank"
                >
                    Datenschutz
                </a>
            </Link>
        </CookieConsent>
    );
}

export default CookieNotice;
